/*
Here we set styling that will override Reactstrap

As of now we have two types of buttons for two different contexts:

    - Primary button:
        - For dark background
        - Transparent with white border

    - Secondary button:
        - For white background
        - Argeo Blue, but with 0.5 opacity

 */
:root {
    --dark-gray-0: #070607;
    --dark-gray-1: #272727;
    --dark-gray-2: #414042;
    --dark-gray-3: #5a5a5a;
    --dark-gray-4: #808080;

    /* --mid-gray-0: #2e3438; */
    /* --mid-gray-1: #484e53; */
    /* --mid-gray-2: #575f65; */
    /* --mid-gray-3: #77838b; */
    /* --mid-gray-4: #9faeb9; */

    /* --purple-0: #06010b; */
    /* --purple-1: #1b0b2f; */
    /* --purple-2: #281146; */
    /* --purple-3: #3c0658; */
    /* --purple-4: #771ca3; */

    /* --blue-purple-0: #344053; */
    /* --blue-purple-1: #455776; */
    /* --blue-purple-2: #65799b; */
    /* --blue-purple-3: #91a3c3; */
    --blue-purple-4: #b8c7e2;

    --light-gray-0: #c3c3c3;
    /* --light-gray-1: #d6d6d6; */
    --light-gray-2: #ebebec;
    --light-gray-3: #f2f2f2;
    --light-gray-4: #ffffff;

    /* --white: #ffffff; */
    --warm-black: #101025;

    /* --amethyst-0: #3c0058; */
    /* --amethyst-1: #771ca3; */
    --amethyst-2: #ab40de;
    /* --amethyst-3: #cd8ceb; */
    --amethyst-4: #e6c6f5;

    /* --citrine-0: #733700; */
    /* --citrine-1: #be6700; */
    --citrine-2: #ffb800;
    /* --citrine-3: #ffd466; */
    --citrine-4: #ffeab3;

    /* --saphire-0: #002866; */
    /* --saphire-1: #004dc3; */
    --saphire-2: #0f6eff;
    /* --saphire-3: #6fa8ff; */
    --saphire-4: #b7d4ff;

    /* --emerald-0: #003715; */
    /* --emerald-1: #00772d; */
    --emerald-2: #00b545;
    /* --emerald-3: #66d38f; */
    --emerald-4: #b3e9c7;

    /* --red-0: #6f0600; */
    /* --red-1: #be0a00; */
    /* --red-2: #ff0d00; Moved */
    /* --red-3: #ff544b; Moved */
    --red-4: #ff9791;

    /* --yellow-0: #722e0a; */
    /* --yellow-1: #bc6619; */
    /* --yellow-2: #fdb72b; Moved */
    /* --yellow-3: #fed36e; Moved */
    --yellow-4: #feeab6;

    /* --green-0: #043617; */
    /* --green-1: #0f7630; */
    /* --green-2: #1bb34b; Moved */
    /* --green-3: #69d291; Moved*/
    --green-4: #b5e8c7;
    --green-5: #a6e4c1;

    /* --blue-0: #032a64; */
    /* --blue-1: #0a51c0; */
    --blue-2: #1d72fb;
    --blue-3: #72a9fc;
    --blue-4: #b9d5fd;

    --primary: #4ccb89;
    --warning: var(--yellow-2);
    --danger: var(--red-3);

    --bs-primary: #11a947;
    --bs-primary-active: #5ccb88;
    --bs-secondary: #d0d0d0;
    --bs-secondary-active: #f0f0f0;
    --bs-info: #0dcaf0;
    --bs-info-active: #69d9ef;
    --bs-warning: #fab237;
    --bs-warning-active: #fbcf6b;
    --bs-danger: #fd281b;
    --bs-danger-active: #fd5247;

    /* ================= */
    /* New design colors */
    /* ================= */

    /* Backgrounds */
    --emphasize-dark: #1c2022;
    --deep-background: #202528;
    --background: #242a2d;
    --foreground: #30383b;
    --emphasize-light: #404a4f;

    /* Text */
    /* --text-high-emphasis: #ffffffde; */
    /* --text-medium-emphasis: #ffffff99; */
    /* --text-disabled: #ffffff61; */
    --text-high-emphasis: #eeeeee;
    --text-medium-emphasis: #acacac;
    --text-disabled: #acacacac;

    /* Quality */
    --quality-0: #db6767;
    --quality-1: #db9667;
    --quality-2: #dbbe67;
    --quality-3: #cedb67;
    --quality-4: #6ddb67;
    --quality-0-desaturated: #c57978;
    --quality-1-desaturated: #c69a79;
    --quality-2-desaturated: #c8b779;
    --quality-3-desaturated: #c1cb7a;
    --quality-4-desaturated: #82ca79;

    --red-2: var(--quality-0);
    --red-3: var(--quality-0-desaturated);

    --yellow-2: var(--quality-2);
    --yellow-3: var(--quality-2-desaturated);

    --green-2: var(--quality-4);
    --green-3: var(--quality-4-desaturated);

    /* Axes */
    --X: #cc3333;
    --Y: #33cc33;
    --Z: #3333cc;
    --X-desaturated: #bf4040;
    --Y-desaturated: #40bf40;
    --Z-desaturated: #4040bf;

    /* Accents */
    --primary: #f58f29;
    --primary-light: #febf80;
    --primary-split: #6aabfb;
    --primary-split-desaturated: #364a63;

    /* Universal Bootstrap */
    --bs-border-width: 2px;
    --bs-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13), 1px 2px 6px 1px rgba(0, 0, 0, 0.13);
    --bs-border-radius: 0;
}
.table {
    --bs-table-color: var(--bs-body-color);
}
.modal .table {
    --bs-table-bg: var(--bs-modal-bg);
}
.btn {
    display: inline-block;
    cursor: pointer;

    height: fit-content;
    width: fit-content;

    text-align: center;
    text-decoration: none;
    text-transform: captialize;

    --bs-btn-font-size: 1rem;
    --bs-font-weight: 500;
    --bs-line-height: 0rem;
    line-height: var(--bs-btn-line-height);

    border-radius: 1.5rem;

    --bs-btn-padding-x: 1.6rem;
    --bs-btn-padding-y: 0.65rem;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);

    --bs-btn-bg: transparent;

    --bs-btn-border-color: var(--bs-btn-color);
    --bs-btn-border-width: 2px;

    --bs-btn-hover-color: var(--warm-black);
    --bs-btn-hover-bg: var(--bs-btn-color);
    --bs-btn-hover-border-color: var(--bs-btn-color);

    --bs-btn-active-color: var(--warm-black);

    --bs-btn-disabled-color: var(--bs-btn-color);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--bs-btn-color);
    --bs-btn-disabled-opacity: 1;

    --bs-btn-focus-shadow-rgb: 128, 128, 128;
}
.btn.uppercase {
    text-transform: uppercase;
}
.btn.underline {
    text-decoration: underline;
}
.btn.full-width {
    width: 100%;
}
.btn.left-text {
    text-align: left;
}
.btn > i {
    padding: 0 0.25rem;
}

.btn-primary,
.btn-success {
    --bs-btn-color: var(--bs-primary);
    --bs-btn-active-bg: var(--bs-primary-active);
    --bs-btn-active-border-color: var(--bs-primary-active);
}
.btn-secondary {
    --bs-btn-color: var(--bs-secondary);
    --bs-btn-active-bg: var(--bs-secondary-active);
    --bs-btn-active-border-color: var(--bs-secondary-active);
}
.btn-info {
    --bs-btn-color: var(--bs-info);
    --bs-btn-active-bg: var(--bs-info-active);
    --bs-btn-active-border-color: var(--bs-info-active);
}
.btn-warning {
    --bs-btn-color: var(--bs-warning);
    --bs-btn-active-bg: var(--bs-warning-active);
    --bs-btn-active-border-color: var(--bs-warning-active);
}
.btn-danger {
    --bs-btn-color: var(--bs-danger);
    --bs-btn-active-bg: var(--bs-danger-active);
    --bs-btn-active-border-color: var(--bs-danger-active);
}
.btn-link {
    --bs-btn-color: var(--bs-info);
    --bs-btn-hover-color: var(--bs-info-active);

    text-decoration: underline;
}

.btn-primary.borderless,
.btn-success.borderless {
    --bs-btn-hover-color: var(--bs-primary-active);
}
.btn-secondary.borderless {
    --bs-btn-hover-color: var(--bs-secondary-active);
}
.btn-info.borderless {
    --bs-btn-hover-color: var(--bs-info-active);
}
.btn-warning.borderless {
    --bs-btn-hover-color: var(--bs-warning-active);
}
.btn-danger.borderless {
    --bs-btn-hover-color: var(--bs-danger-active);
}

.btn.white {
    --bs-btn-color: white;
    --bs-btn-hover-color: var(--bs-body-color);
}
.btn.red {
    --bs-btn-color: var(--red-2);
    --bs-btn-hover-color: var(--red-3);
}
.btn.borderless.red:not(.full-color) {
    --bs-btn-hover-color: var(--red-2);
}
.btn.yellow {
    --bs-btn-color: var(--yellow-2);
    --bs-btn-hover-color: var(--yellow-3);
}
.btn.borderless.yellow:not(.full-color) {
    --bs-btn-hover-color: var(--yellow-2);
}
.btn.green {
    --bs-btn-color: var(--green-2);
    --bs-btn-hover-color: var(--green-3);
}
.btn.borderless.green:not(.full-color) {
    --bs-btn-hover-color: var(--green-2);
}
.btn.blue {
    --bs-btn-color: var(--blue-2);
    --bs-btn-hover-color: var(--blue-3);
}
.btn.borderless.blue:not(.full-color) {
    --bs-btn-hover-color: var(--blue-2);
}
.btn.purple {
    --bs-btn-color: #cb8fe8;
    --bs-btn-hover-color: #e6c7f4;
}
.btn.borderless.purple:not(.full-color) {
    --bs-btn-hover-color: #cb8fe8;
}
.btn.light-blue {
    --bs-btn-color: var(--blue-4);
    --bs-btn-hover-color: #e6f0fe;
}
.btn.borderless.light-blue:not(.full-color) {
    --bs-btn-hover-color: var(--blue-4);
}
.btn.tan {
    --bs-btn-color: var(--yellow-4);
    --bs-btn-hover-color: var(--yellow-5);
}
.btn.borderless.tan:not(.full-color) {
    --bs-btn-hover-color: var(--yellow-4);
}

.btn-link:not(.full-color),
.btn.borderless:not(.full-color) {
    --bs-btn-color: var(--bs-secondary);
}

.btn-link,
.btn.borderless {
    --bs-btn-active-color: var(--bs-secondary);

    height: fit-content;

    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;

    --bs-btn-border-width: 0;
    --bs-btn-hover-bg: transparent;
    --bs-btn-active-bg: transparent;
    --bs-btn-hover-border-color: transparent;

    border-radius: 0;
}

.btn.circle {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 3rem;
    max-width: 3rem;
    --bs-btn-padding-x: 0.25rem;
    --bs-btn-padding-y: 0.25rem;

    font-size: 1.5rem;
    line-height: 1.6;

    color: var(--bs-body-color);
}
.btn.circle:hover {
    color: var(--bs-body-bg);
}

.icon-red {
    color: var(--red-2);
}
.icon-green {
    color: var(--green-2);
}
.icon-yellow {
    color: var(--yellow-2);
}

.dropdown {
    cursor: pointer;
}

.dropdown-item {
    color: var(--bs-body-color);
}

.dropdown-menu {
    background-color: var(--bs-body-bg);
    text-align: center;
}

.card {
    background-color: var(--bs-body-bg);
    border-radius: 0;
}
.card::after {
    content: ' ';
    position: absolute;
    top: 15px;
    right: 100%; /* To the left of the card */
    border-width: var(--spacer);
    border-style: solid;
    border-color: transparent var(--bs-body-bg) transparent transparent;
}
.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    word-break: break-all;
}

.tab-pane {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 28.125rem;
    min-height: 100%;
    padding: calc(3 * var(--spacer));
    padding-top: var(--spacer);
    color: var(--bs-body-color);
    overflow-y: auto;
}
.tab-pane-collapsed {
    min-width: auto;
}

.nav.nav-tabs .nav-item {
    cursor: pointer;
    text-align: center;
}

/* Fix for tabs in admin & modals, rework when rebuilding admin*/

.modal-dialog {
    padding: calc(4 * var(--spacer));
}
.modal-title {
    font-size: 1.5rem;
    color: var(--light-gray-2);
}

.nav-tabs .nav-item.show .nav-link {
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-color: var(--tabs-border-color) var(--tabs-border-color) var(--bs-body-color);
}

.nav-tabs .nav-link.active {
    color: var(--menu-highlight-color);
    background-color: inherit;
    border-color: var(--tabs-border-color) var(--tabs-border-color) var(--bs-body-color);
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    box-shadow: none;
}
.modal-close:focus {
    border: none;
    box-shadow: none;
}

.modal-content {
    border-radius: var(--menu-border-radius);
    padding: calc(2 * var(--spacer));
    min-height: 40rem;
}
.modal-header .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
    padding: var(--bs-modal-header-padding-y) var(--bs-modal-header-padding-x);
}
.modal-body {
    padding: calc(2 * var(--spacer));
    display: flex;
    flex-direction: column;
}
.modal-footer {
    border-top: transparent;
}

.modal-confirm {
    --bs-modal-width: 32rem;

    .modal-header {
        border-bottom: transparent;
        min-height: 4rem;
    }

    .modal-body {
        align-items: center;
        justify-content: center;
    }

    .modal-footer {
        justify-content: space-evenly;
    }
}

.modal-unfocus .modal-content {
    display: none;
}

.modal-body.centred {
    justify-content: center;
}
.modal-footer {
    gap: 0.5rem;
}
.modal-footer > span {
    color: var(--light-gray-0);
}

.form-group {
    & + .form-group {
        margin-top: 0.5rem;
    }

    input[type='text'],
    input[type='number'],
    textarea {
        color: var(--text-medium-emphasis);
        background-color: var(--emphasize-dark);
        width: 100%;
        border: none;
        text-indent: 2px;
    }

    &.date-range {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;

        input {
            border: none;
            color-scheme: dark;
            color: var(--text-medium-emphasis);
            background-color: var(--emphasize-dark);
            padding-inline: 0.25rem;
            flex-grow: 1;
        }

        input::-webkit-datetime-edit-month-field,
        input::-webkit-datetime-edit-day-field,
        input::-webkit-datetime-edit-year-field {
            color: var(--bs-body-color);
        }
    }
}

.form-group {
    .uppy-DragDrop-inner {
        padding: 2rem;
    }
    .uppy-DragDrop-arrow {
        margin: 0;
    }
    .uppy-DragDrop-container {
        background-color: transparent;
        border-radius: 0;
        border: dashed var(--foreground) 2px;
    }
    .uppy-DragDrop-label {
        display: none;
    }
    .uppy-DragDrop-note {
        color: var(--bs-body-color);
        font-size: 1.25rem;
    }
}

.form-check {
    display: inline-block;
    margin-right: var(--spacer);
}

.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    right: 0;
    left: 0;
    top: 1rem;
    margin: 0 auto;

    width: fit-content;
    z-index: 1;

    background-color: var(--menu-bg-color);
    color: var(--bs-body-color);

    border-radius: 1rem;
}
.title-bar > .title {
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0;

    letter-spacing: 0.04rem;
    font-weight: bold;

    text-transform: uppercase;
    text-align: center;
    word-break: break-word;
}

.overview-project-tools {
    position: absolute;
    left: 1rem;
    top: 1rem;

    z-index: 1;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.overview-project-tools .btn {
    --bs-btn-bg: var(--dark-gray-0);
}

.text-yellow {
    color: var(--yellow-3);
}
.text-green {
    color: var(--green-3);
}
.text-purple {
    color: #e6c7f4;
}
.text-tan {
    color: var(--yellow-4);
}

.modal-icon {
    font-size: 10rem;
    text-align: center;
    padding: calc(4 * var(--spacer));
    color: var(--dark-gray-4);
}
.modal-icon-good {
    color: var(--primary);
}
.modal-icon-warn {
    color: var(--yellow-2);
}
.modal-icon-bad {
    color: var(--red-3);
}

.annotation-content {
    margin-right: -1rem;
}
.annotation-comments-icon {
    width: 2.5rem;
    display: flex;
    align-items: center;
}
.annotation-comments-icon > span {
    color: var(--bs-body-color);
}

.point-annotation {
    position: absolute;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #99ff9999;
    border: 2px solid #006600;
    font: 14px futura-pt;
    font-weight: bold;
    pointer-events: auto;
    cursor: pointer;
}

.point-annotation-hovered {
    background-color: #ffe53f64;
    border: 2px solid #ffee00;
}

.point-annotation-selected {
    background-color: #480f7399;
    border: 2px solid #050405;
}

.notification-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 0.8125rem;
    border-bottom: var(--foreground) 2px solid;
}
.notification-item.active,
.notification-item:hover {
    background-color: var(--menu-bg-color);
}
.notification-icon {
    font-size: 1.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
}
.notification-item > .btn {
    display: none;
    color: var(--green-3);
    padding: 0;
    margin: 0;
    align-self: center;
    margin-bottom: 1rem;
}
.notification-item > .btn.error {
    color: var(--red-4);
}
.notification-item > .btn:hover {
    color: var(--bs-body-color);
}
.notification-item > .btn i {
    margin-left: 0.5rem;
    font-size: 1.5rem;
    float: right;
}
.notification-item.active > .btn {
    display: initial;
}
.notification-upper {
    display: flex;
    flex-direction: row;
}
.notification-text {
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
}
.notification-timestamp {
    color: var(--blue-4);
    font-size: 0.75rem;
    font-weight: 1000;
}
.read .notification-timestamp {
    color: var(--blue-purple-4);
}
.notification-title {
    font-size: 0.75rem;
    font-weight: normal;
}
.notification-content {
    padding-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    max-width: 20rem;
}
.active > div > div > .notification-content {
    white-space: initial;
}
.notification-content > mark {
    background-color: transparent;
    color: #e6c7f4;
    padding: 0;
}
.notification-item.read .notification-text .notification-content {
    font-weight: normal;
}

.notification-item > .dropdown-tag-container {
    display: none;
}
.notification-item.active > .dropdown-tag-container,
.notification-item:hover > .dropdown-tag-container {
    display: initial;
}

.green .notification-icon {
    color: var(--green-2);
}
.green .notification-title,
.green .dropdown-tag {
    color: var(--green-3);
}

.green.read .notification-icon,
.green.read .notification-title {
    color: var(--green-4);
}

.yellow .notification-icon {
    color: var(--yellow-2);
}
.yellow .notification-title,
.yellow .dropdown-tag {
    color: var(--yellow-3);
}

.yellow.read .notification-icon,
.yellow.read .notification-title {
    color: var(--yellow-4);
}

.red .notification-icon {
    color: var(--red-3);
}
.red .notification-title,
.red .dropdown-tag {
    color: var(--red-4);
}

.red.read .notification-icon,
.red.read .notification-title {
    color: var(--red-3);
} /* FIX this is not in the design but I'm assuming we mean to fade this */

.error.read .notification-icon,
.error.read .notification-title {
    color: var(--red-4);
}

.dropdown-tag-container {
    max-height: 0;
    align-self: center;
}
.dropdown-tag-container > .dropdown-tag {
    padding: 0;
    font-size: 1.125rem;

    border-radius: 50%;
    min-width: 1.625rem;
    min-height: 1.625rem;
    max-width: 1.625rem;
    max-height: 1.625rem;

    transform: translateY(-0.75rem);

    --bs-btn-border-color: var(--dark-gray-1);
    --bs-btn-hover-border-color: var(--dark-gray-1);
    --bs-btn-active-border-color: var(--dark-gray-1);
    --bs-btn-border-width: 1px;
    --bs-btn-bg: var(--menu-bg-color);
    --bs-btn-hover-bg: var(--menu-bg-color);
    --bs-btn-active-bg: var(--menu-bg-color);
}
.dropdown-tag i {
    transform: translateY(-0.09rem);
}

.sideform > .form-group > label,
.faded-text {
    color: var(--light-gray-0);
    font-weight: lighter;
    margin-left: var(--spacer);
}
.faded-text.green {
    color: var(--green-3);
    margin-left: 0;
}

.title-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 1.2rem;
    justify-items: center;
}
.title-button {
    border: none;
    padding: 0;
    background: none;
    color: var(--bs-body-color);
    text-align: left;
    flex-grow: 1;
    word-wrap: break-word;
}

.inline-drop-menu {
    position: relative;
    width: fit-content;

    button {
        padding: 0;
    }
}
.inline-drop-menu:hover .inline-drop-content,
.inline-drop-menu:focus-within .inline-drop-content {
    display: flex;
    flex-direction: row;
}
.inline-drop-content {
    display: none;
    position: absolute;
    background-color: var(--background);
    top: -2px;
    right: -2px;
    border: solid 2px var(--foreground);
    z-index: 1;
    box-shadow: var(--bs-box-shadow);
}

.map-controls-drop-menu {
    position: relative;
    width: fit-content;
}
.map-controls-drop-menu.open .map-controls-drop-content {
    display: flex;
    flex-direction: column;
}
.map-controls-drop-content {
    display: none;
    position: absolute;
    background-color: #070607b2;
    bottom: -0.5rem;
    right: -0.5rem;
    border-radius: 2rem;
    padding: 0.5rem;
    gap: 0.5rem;
    z-index: 1;
}

.centre-drop-menu {
    position: relative;
    width: fit-content;
}
.centre-drop-content {
    display: none;
    position: absolute;
    background-color: var(--background);
    border: solid 2px var(--foreground);
    z-index: 1;
    box-shadow: var(--bs-box-shadow);
}
.centre-drop-content > .btn {
    width: 100%;
}
.centre-drop-menu:hover .centre-drop-content,
.centre-drop-menu:focus-within .centre-drop-content {
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.top-right {
        top: 0;
        left: unset;
        right: 0;
        transform: unset;
    }
}

.centre-drop-menu-divider {
    border: none;
    border-top: 1px dashed var(--foreground);
    opacity: 100%;
    height: 0px;
    padding: 0;
    margin: 0;
    width: 100%;
}

.scroll-prompt {
    min-width: 100%;
    font-size: larger;
    z-index: 2;
    text-align: center;
    transition: color 0.25s ease-in-out;
}
.scroll-prompt-bottom {
    flex-grow: 1;
}
.scroll-prompt.hidden {
    color: transparent;
}

.sideform {
    display: flex;
    flex-direction: column;
    height: max-content;
    flex-grow: 1;
}
.sideform-bar {
    margin: 0.5rem 0;
    opacity: 100%;
    color: #0f0f0f;
}
.sideform-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem;
}
.sideform .invalid-feedback {
    color: var(--red-3);
}
.sideform .form-control.is-invalid,
.sideform .was-validated .form-control:invalid {
    background: none;
}

.sideform .form-group {
    margin: 0;
}

.profile-line-geometry {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.profile-line-geometry table {
    border-collapse: collapse;
}
.profile-line-geometry td:first-child {
    width: 3rem;
}
.profile-line-geometry td:last-child {
    width: 15rem;
}
.profile-line-geometry td {
    border-bottom: 1px solid;
}
.profile-line-geometry tr:not(:last-child) td {
    padding-bottom: 0.5rem;
}
.profile-line-geometry tr:last-child td {
    border-bottom: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
}
.grow {
    flex-grow: 1;
}
.shrink {
    flex-shrink: 1;
    overflow: scroll;
}

.comment {
    border-radius: 0.5rem;
    background-color: var(--emphasize-dark);
    padding: 0.5rem;
    margin: 0;
    font-size: 0.875rem;
    overflow-wrap: break-word;
}
.comment.indent {
    margin-left: 1rem;
    margin-top: 0.5rem;
}
.comment-author-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    color: var(--green-4);
    font-size: 0.75rem;
}
.comment-author-line > i {
    font-size: 0.875rem;
    transform: translateY(1px);
}
.comment-author {
    flex-grow: 1;
}
.comment-author-line.resolved {
    color: var(--light-gray-0);
}
.comment-author-line.resolved > i {
    color: var(--light-gray-0);
}
.comment-content {
    white-space: pre-wrap;
}

.comment-list .comment-form {
    padding-right: 0;
}
.comment-form.indent {
    padding: 0 0 0 1rem;
}
.comment-field {
    margin: 2px;
    border-radius: 0.5rem;
    background-color: var(--emphasize-dark);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    overflow-wrap: break-word;
    outline: 0;
}
.comment-field:empty:not(:focus)::before {
    content: attr(placeholder);
    color: var(--yellow-4);
    cursor: text;
    pointer-events: none;
}
.comment-field:focus-visible {
    border: solid 1px var(--bs-body-color);
}
.comment-control-row {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1rem 0;
    font-size: 1.125rem;
}
.comment-controls {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    padding-right: 2rem;
}
.comment-control-row button {
    padding: 0;
}
.comment-control {
    color: var(--light-gray-2);
}
.comment-send {
    color: var(--green-2);
}
.comment-edit-controls {
    display: flex;
    flex-direction: row-reverse;
}
.comment-form .comment-field:not(:empty) + .comment-control-row,
.comment-form:focus-within .comment-control-row {
    display: flex;
}
.comment-replies-line {
    height: 2rem;
}
.comment-replies-line > .btn {
    float: right;
    display: flex;
    font-size: 0.75rem;
    padding: 0.25rem;
}
.comment-replies-line > .btn > i {
    max-height: 1rem;
    font-size: 1.125rem;
    padding-right: 0.25rem;
}
.comment-content > mark {
    background-color: transparent;
    color: #e6c7f4;
    padding: 0;
}

.geometry-select {
    table-layout: fixed;
    width: 100%;
}
.geometry-select button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 0.625rem;
}
.geometry-select button i::before {
    font-size: 1.25rem;
    display: inline-block;
}

.annotation-filters .geometry-select button {
    --bs-btn-active-color: var(--bs-body-color);
    --bs-btn-hover-bg: var(--green-3);
    --bs-btn-hover-border-color: var(--green-3);
    --bs-btn-active-bg: transparent;
    --bs-btn-active-color: var(--green-3);
    --bs-btn-active-border-color: var(--green-3);
}
.annotation-filters .geometry-select button.selected {
    --bs-btn-color: var(--green-3);
    --bs-btn-border-color: var(--green-3);
    text-decoration-line: underline;
}

.capitalize-first::first-letter {
    text-transform: capitalize;
}

.error-text {
    color: var(--bs-danger);
    font-weight: 500;
}
.error-text.minor {
    color: var(--bs-warning);
}

.sorting-arrow {
    background-color: transparent;
    color: var(--bs-body-color);
    text-align: center;
    font-weight: bold;
}

.library-control-row {
    display: flex;
    align-items: center;
    gap: 1rem;

    max-width: 100%;
    height: 2.635rem;

    font-weight: 700;
}
.library-control-row.filters {
    gap: 4rem;
    flex-wrap: wrap;
}
.library-control-row.searchbar {
    margin-bottom: 1rem;
}
.library-control-row .searchbox {
    width: 30rem;
}
.library-control-row .dropdown-indicator-container {
    padding-left: 0.5rem;
    text-decoration: none;
}
.library-control-row .control {
    color: #c3c3c3;
    width: fit-content;
    text-align: right;
}
.library-control-row .multi-value {
    font-weight: 500;
    text-decoration: underline;
}
.library-control-row .option {
    color: #c3c3c3;
    font-weight: 500;
    padding: 0 0.5rem;
    white-space: nowrap;
}
.library-control-row .menu {
    z-index: 2;
    width: fit-content;
    background-color: var(--bs-body-bg);
    border: solid 1px #c3c3c3;
    border-top: none;
    border-left: none;
    right: 0;
    border-radius: 0 0 0.5rem 0;
}

.project-views-table td {
    vertical-align: middle;
}
.project-views-table td:last-child,
.project-dataset-table td:last-child {
    width: 0;
}
.project-dataset-table td:last-child label {
    float: right;
}

input[type='file'] {
    display: none;
}
.file-dropzone {
    padding: 1rem;
    border: dashed 2px transparent;
    border-radius: 1rem;
}
.file-dropzone.dragging {
    border-color: var(--dark-gray-4);
}
.file-upload-button {
    color: var(--dark-gray-0);
    background-color: var(--light-gray-2);
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    font-weight: 700;
    cursor: pointer;
}
.file-upload-button > i {
    font-weight: 400;
}
.file-upload-button:hover {
    background-color: var(--green-3);
}
.file-upload-list {
    font-weight: bold;
}

.large-file-dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    pointer-events: none;

    & > div {
        margin: 1rem;
        border: dashed 3px transparent;
        border-color: var(--dark-gray-4);
        background-color: #27272780; /* --dark-gray-1 with 50% opacity */

        position: absolute;
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);

        pointer-events: none;

        & > div {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: larger;

            & > i {
                font-size: x-large;
            }
        }
    }

    &.dragging {
        opacity: 1;
        pointer-events: all;
    }
}

.fit-content {
    max-width: fit-content;
    max-height: fit-content;
}

.legend-box {
    z-index: 1;
    padding: calc(var(--spacer) / 2) var(--spacer);
    padding: 1rem 2rem;
}
.ol-control.legend-box button:hover {
    background-color: var(--menu-bg-color);
}

.outlet .error-fallback-message {
    margin: auto;
}

.error-detail-label {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    padding-top: 1.5rem;
}

.elevation-profile-tooltip {
    background-color: var(--background);
    border-color: var(--foreground);
    border-width: 2px;
    color: var(--text-high-emphasis);

    table tbody tr td span {
        display: inline-block;
        border-radius: 10px;
        width: 10px;
        height: 10px;
    }
}

td.default-view {
    font-weight: bold;
}
td.default-view::before {
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    content: '\f0ac';
    padding-right: 0.5rem;
}

.modal-header.with-button > h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.select-tool {
    position: absolute;
    z-index: 101;
    pointer-events: none;
    overflow: visible;
}

.squarewrapper {
    pointer-events: none;
    overflow: visible;
    width: 10rem;
    height: 10rem;
}

.select-tool .square {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.csscircle {
    position: absolute;
    top: -0%;
    left: -0%;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.segmentwrapper {
    position: absolute;
    top: 49%;
    left: 49%;
    width: 2%;
    height: 2%;
}

.segment {
    bottom: 50%;
    right: 50%;
    width: 5000%;
    height: 5000%;
    overflow: hidden;
    transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    -webkit-transform-origin: 0 100%;
    background: none;
    border: none;
}
.segment:focus-visible {
    outline: none;
}
.segment:focus-visible + .outer {
    display: block;
}

.segment,
.segment .inner,
.segmentwrapper .outer {
    position: absolute;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.segmentwrapper .outer {
    display: none;
}
.segmentwrapper:hover .outer {
    display: block;
}
.segmentwrapper .segment .inner {
    right: -50%;
    bottom: -50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(#0000 0, #0000 25%, var(--foreground) 26%, var(--foreground) 100%);
    border: solid var(--emphasize-light) 2px;
    border-radius: 100%;
    transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    cursor: pointer;
    pointer-events: auto;
}
.segmentwrapper.nohover .segment .inner {
    opacity: 50%;
    cursor: default;
}

.segment .inner > div {
    width: 0;
    height: 0;
    transform: rotate(-67.5deg);
    -ms-transform: rotate(-67.5deg);
    -moz-tranform: rotate(-67.5deg);
    -webkit-transform: rotate(-67.5deg);
    -o-transform: rotate(-67.5deg);
    translate: 1.8rem 3.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.segment .inner > div > i {
    font-size: x-large;
    color: var(--text-high-emphasis);
    pointer-events: none;
}

.segmentwrapper > .outer {
    transform: rotate(-67.5deg) translateY(-5rem);
    -ms-transform: rotate(-67.5deg) translateY(-5rem);
    -moz-tranform: rotate(-67.5deg) translateY(-5rem);
    -webkit-transform: rotate(-67.5deg) translateY(-5rem);
    -o-transform: rotate(-67.5deg) translateY(-5rem);

    max-width: 0;
    max-height: 0;
}

.segmentwrapper > .outer > div {
    position: absolute;
}

.segmentwrapper > .outer > div > label {
    position: absolute;
    white-space: nowrap;

    color: white;
    font-weight: bold;
    text-shadow: 0 0 4px black;
}
.segmentwrapper.nohover > .outer > div > label {
    opacity: 50%;
}

.circle8 .segment {
    transform: skewX(45deg);
    -ms-transform: skewX(45deg);
    -moz-tranform: skewX(45deg);
    -webkit-transform: skewX(45deg);
    -o-transform: skewX(45deg);
}

.circle8 .segment .inner {
    transform: skewX(-45deg);
    -ms-transform: skewX(-45deg);
    -moz-tranform: skewX(-45deg);
    -webkit-transform: skewX(-45deg);
    -o-transform: skewX(-45deg);
}

.circle8 .segmentwrapper:not(.nohover) .segment .inner:hover,
.circle8 .segment:focus-visible .inner {
    transform: skewX(-45deg) scale(1.1);
    -ms-transform: skewX(-45deg) scale(1.1);
    -moz-tranform: skewX(-45deg) scale(1.1);
    -webkit-transform: skewX(-45deg) scale(1.1);
    -o-transform: skewX(-45deg) scale(1.1);
}
.circle8 .segmentwrapper:not(.nohover) .segment:has(.inner:hover) + .outer {
    transform: rotate(-67.5deg) translateY(-6rem);
    -ms-transform: rotate(-67.5deg) translateY(-5.5rem);
    -moz-tranform: rotate(-67.5deg) translateY(-5.5rem);
    -webkit-transform: rotate(-67.5deg) translateY(-5.5rem);
    -o-transform: rotate(-67.5deg) translateY(-5.5rem);
}

.circle8 .segment7 {
    transform: rotate(22.5deg);
    -ms-transform: rotate(22.5deg);
    -moz-tranform: rotate(22.5deg);
    -webkit-transform: rotate(22.5deg);
    -o-transform: rotate(22.5deg);
}
.segment7 .segment .inner > div > i,
.segment7 > .outer > div {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-tranform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.segment7 > .outer > div > label {
    bottom: 0;
    right: 0;
}

.circle8 .segment6 {
    transform: rotate(337.5deg);
    -ms-transform: rotate(337.5deg);
    -moz-tranform: rotate(337.5deg);
    -webkit-transform: rotate(337.5deg);
    -o-transform: rotate(337.5deg);
}
.segment6 .segment .inner > div > i,
.segment6 > .outer > div {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-tranform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.segment6 > .outer > div > label {
    right: 0;
    transform: translateY(-50%);
}

.circle8 .segment5 {
    transform: rotate(292.5deg);
    -ms-transform: rotate(292.5deg);
    -moz-tranform: rotate(292.5deg);
    -webkit-transform: rotate(292.5deg);
    -o-transform: rotate(292.5deg);
}
.segment5 .segment .inner > div > i,
.segment5 > .outer > div {
    transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -moz-tranform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
}
.segment5 > .outer > div > label {
    top: 0;
    right: 0;
}

.circle8 .segment4 {
    transform: rotate(247.5deg);
    -ms-transform: rotate(247.5deg);
    -moz-tranform: rotate(247.5deg);
    -webkit-transform: rotate(247.5deg);
    -o-transform: rotate(247.5deg);
}
.segment4 .segment .inner > div > i,
.segment4 > .outer > div {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-tranform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.segment4 > .outer > div > label {
    top: 0;
    transform: translateX(-50%);
}

.circle8 .segment3 {
    transform: rotate(202.5deg);
    -ms-transform: rotate(202.5deg);
    -moz-tranform: rotate(202.5deg);
    -webkit-transform: rotate(202.5deg);
    -o-transform: rotate(202.5deg);
}
.segment3 .segment .inner > div > i,
.segment3 > .outer > div {
    transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -moz-tranform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -o-transform: rotate(225deg);
}
.segment3 > .outer > div > label {
    top: 0;
    left: 0;
}

.circle8 .segment2 {
    transform: rotate(157.5deg);
    -ms-transform: rotate(157.5deg);
    -moz-tranform: rotate(157.5deg);
    -webkit-transform: rotate(157.5deg);
    -o-transform: rotate(157.5deg);
}
.segment2 .segment .inner > div > i,
.segment2 > .outer > div {
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -moz-tranform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
}
.segment2 > .outer > div > label {
    left: 0;
    transform: translateY(-50%);
}

.circle8 .segment1 {
    transform: rotate(112.5deg);
    -ms-transform: rotate(112.5deg);
    -moz-tranform: rotate(112.5deg);
    -webkit-transform: rotate(112.5deg);
    -o-transform: rotate(112.5deg);
}
.segment1 .segment .inner > div > i,
.segment1 > .outer > div {
    transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -moz-tranform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -o-transform: rotate(315deg);
}
.segment1 > .outer > div > label {
    left: 0;
    bottom: 0;
}

.circle8 .segment0 {
    transform: rotate(67.5deg);
    -ms-transform: rotate(67.5deg);
    -moz-tranform: rotate(67.5deg);
    -webkit-transform: rotate(67.5deg);
    -o-transform: rotate(67.5deg);
}
.segment0 > .outer > div > label {
    bottom: 0;
    transform: translateX(-50%);
}

/* Makes a deadzone in the centre of the circle */
.circle8:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    background: #0000;
    border-radius: 1000px;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: all;
}

.context-select-list,
.context-select-list .popover,
.context-select-list .popover-inner {
    background-color: transparent;
}
.context-select-list .popover-inner {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}
.context-select-list .popover-inner > Button {
    background-color: var(--bs-popover-bg);
    border: none;
    border-radius: 0.75rem;
    width: 100%;
    text-align: left;
    flex-grow: 1;
    padding: 2px 4px;
}
.context-select-list .popover-inner > Button:hover {
    color: var(--yellow-4);
}

.annotation-label {
    position: 'absolute';
    border-radius: '50%';
    width: '1rem';
    height: '1rem';
    background-color: '#99ff9999';
    border: '2px solid #006600';
    font: '14px futura-pt';
    font-weight: 'bold';
    pointer-events: 'auto';
    cursor: 'pointer';
}

.upload-group progress,
.upload-list progress {
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 4px;
}

.upload-group progress[value]::-webkit-progress-value {
    background-color: var(--blue-2);
}

.upload-group-title,
.completed-upload {
    border-bottom: 2px solid var(--dark-gray-3);
    font-weight: bold;
    padding: 0.25rem 0.5rem;
}

.upload-group-list > li {
    display: flex;
    flex-direction: column;
    padding: 0 0.25rem;
}

.upload-group.complete .upload-group-list > li {
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.upload-list > li {
    display: flex;
    flex-direction: column;
}

.file-readout {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    span {
        flex-grow: 1;
    }
}

.tabContent.freeScroll {
    padding: 0;

    & > table {
        tr > td:first-child,
        tr > th:first-child {
            border-left: none;
        }

        tr > td:last-child,
        tr > th:last-child {
            border-right: none;
        }

        tr:last-child {
            td,
            th {
                border-bottom: none;
            }
        }
    }

    &::-webkit-scrollbar:vertical {
        border-left: 2px solid var(--foreground);
        width: 18px;
    }

    &::-webkit-scrollbar-thumb:vertical {
        border-left: 8px solid transparent;
        border-top-left-radius: 9.5px;
        border-bottom-left-radius: 9.5px;
    }

    &::-webkit-scrollbar:horizontal {
        border-top: 2px solid var(--foreground);
        height: 18px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        border-top: 8px solid transparent;
        border-top-left-radius: 9.5px;
        border-top-right-radius: 9.5px;
    }

    &::-webkit-scrollbar-corner {
        background-color: var(--foreground);
    }
}

.tabContent > table,
.tabContent > .scrollable > table,
.tabContent.fixedHeight > .scrollable > table {
    &.firstColumn td:first-child:not(.title) {
        background-color: var(--foreground);
        font-weight: bold;
    }

    td.title:first-child {
        font-weight: bold;
        border-bottom-color: var(--emphasize-light);

        button {
            font-weight: bold;
        }
    }

    td,
    th {
        padding: 0 0.2rem;
        background-color: var(--background);
        border-left: solid 2px var(--foreground);
        border-right: solid 2px var(--foreground);
    }

    td {
        border: solid 2px var(--foreground);
    }

    thead,
    thead th {
        background-color: var(--foreground);
        top: 0;
        position: sticky;
    }

    tr button {
        padding: 0;
        & > i {
            padding: 0;
        }
    }

    tr:nth-child(even) td {
        background-color: var(--deep-background);
    }

    tr.highlight td {
        background-color: var(--primary-split-desaturated);
    }
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.select {
    margin: 1px;
    background-color: var(--emphasize-dark);
    color: var(--text-medium-emphasis);

    .select-control {
        min-height: 19px;
        height: 100%;
        flex-grow: 1;
    }

    .select-value-container {
        padding-left: 0.25rem;
    }

    .select-menu {
        background-color: var(--emphasize-dark);
        border: solid 2px var(--emphasize-light);
    }

    .select-option {
        display: flex;
        align-items: center;
        height: 25px;

        overflow-x: hidden;
        white-space: nowrap;

        &:hover {
            background-color: var(--background);
        }

        .multiselect-checkbox {
            color: #bbbbbb;
            font-size: larger;
            padding-right: 0.25rem;
        }

        &:hover > .multiselect-checkbox,
        .multiselect-checkbox.checked {
            color: var(--primary);
        }
        .multiselect-option.faded .multiselect-checkbox {
            color: var(--light-gray-0);
        }
    }

    .select-group {
        .select-group-heading {
            display: flex;
            height: 25px;

            &:hover {
                background-color: var(--background);
            }

            button {
                color: var(--text-medium-emphasis);
                border: none;
                background-color: transparent;
                padding: 0 4px;

                &.group-toggle {
                    font-size: 1.125rem;

                    &.checked,
                    &:hover {
                        color: var(--primary);
                    }
                }

                &:not(.group-toggle) {
                    flex-grow: 1;
                    text-align: left;
                    font-weight: bold;

                    & > i {
                        padding: 4px;
                        padding-left: 0;
                    }
                }
            }

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
        }

        &:has(.select-group-heading > input:checked) {
            .select-option {
                display: flex;
            }
        }

        .select-option {
            display: none;
            padding-left: 1.125rem;
        }
    }

    .select-no-options {
        height: 25px;
        line-height: 25px;
    }

    &.mentionselect {
        flex-grow: 1;
        height: 1.5rem;
        font-size: 0.75rem;

        .select-dropdown-indicator {
            display: none;
        }
    }

    &.search-bar {
        width: 20rem;
        height: 2rem;
        font-size: 1rem;

        .select-control {
            height: 2rem;
        }

        .select-dropdown-indicator {
            display: none;
        }
    }

    &.library-filter {
        width: 10rem;
    }
}

input.search-bar {
    background-color: var(--emphasize-dark);
    color: var(--text-high-emphasis);
    border: none;
}

/* Reactstrap Element styles */
.popover {
    --bs-popover-max-width: 25rem;
    --bs-popover-font-size: 0.875rem;
    --bs-popover-bg: var(--background);
    --bs-popover-border-color: var(--foreground);
    --bs-popover-border-radius: 0;
    --bs-popover-inner-border-radius: 0;

    --bs-popover-header-padding-x: 0.5rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 1rem;
    --bs-popover-header-color: inherit;
    --bs-popover-header-bg: var(--bs-popover-bg);
    --bs-popover-body-padding-x: 0.5rem;
    --bs-popover-body-padding-y: 0.5rem;

    box-shadow: var(--bs-box-shadow);

    .popover-inner {
        border-radius: var(--bs-popover-inner-border-radius);
    }

    .popover-body {
        max-height: 90vh;
        padding: 0;
        padding-bottom: 2px;

        & > span {
            margin: 0 0.5rem;
        }
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
.popover-style ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tooltip {
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-color: var(--text-high-emphasis);
    --bs-tooltip-bg: var(--emphasize-dark);
    --bs-tooltip-border-radius: 0;
    --bs-tooltip-opacity: 1;

    line-height: 1;
    border: solid var(--foreground) 2px;
    box-shadow: 1px 1px 2px #000000;

    pointer-events: none;
}

.modal {
    --bs-modal-bg: var(--background);
    --bs-modal-width: 64rem;
    --bs-modal-color: var(--text-medium-emphasis);
    --bs-modal-border-color: var(--foreground);
    --bs-modal-border-radius: 0;
    --bs-modal-inner-border-radius: 0;
    --bs-modal-border-width: var(--menu-border-width);

    form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal-dialog.wide {
    --bs-modal-width: 100rem;
}

.alert {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    --bs-alert-padding-x: 0.5rem;
    --bs-alert-padding-y: 0.5rem;
    --bs-alert-margin-bottom: 0;

    background-color: var(--foreground);
    border-color: var(--emphasize-light);
    color: white;
}

.description {
    margin: 2px;
    border-radius: 0.5rem;
    background-color: var(--emphasize-dark);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    overflow-wrap: break-word;

    .title {
        font-weight: bold;
    }

    .author-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        color: var(--green-4);
        font-size: 0.75rem;

        & > i {
            font-size: 0.875rem;
            transform: translateY(1px);
        }
    }
}
